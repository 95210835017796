import React from "react";

export default function StatusIndicator({ status, className = "" }) {

    const style = { padding: '3px 6px', color: '#fff', display: 'inline-block', borderRadius: 3, fontSize: 9 }

    if(status === 'pending') style.backgroundColor = '#ff9800';
    if(status === 'Generating ID') style.backgroundColor = '#ff9800';

    if(status === 'approved') style.backgroundColor = 'green';
    if(status === 'active') style.backgroundColor = 'green';
    if(status === 'generated') style.backgroundColor = 'green';

    if(status === 'blocked') style.backgroundColor = 'red';

    return <div className={className + ' status-' + status?.toLowerCase()} style={{...style}}><b>{status?.toUpperCase()}</b></div>
}