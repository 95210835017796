import React from "react";
import RegisterImage from "../../Assets/web-bg.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Grid, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import colors from "../../Config/colors";

export default function Home() {

    const navigate = useNavigate();

    return (
        <div style={{height: '100vh', background: '#fff'}}>
            <div style={{justifyContent: 'center', display: 'flex',height: '100vh'}}>
                <div style={{width: '80%'}}>
                    <div style={{marginTop: '8rem'}}>
                        <Grid container spacing={2} style={{justifyContent: 'center'}}>
                            <Grid item md={4}>
                                <img style={{width: '100%'}} src={RegisterImage} alt="container"/>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{marginTop: 50, textAlign: 'center'}}>
                        <div style={{fontSize: 20, fontWeight: 'bold'}}>ENTREPRENEUR'S MEET</div>
                        <div style={{marginTop: 20, fontSize: 20}}>
                            Registration Completed.
                        </div>
                        <div style={{marginTop: 20, textAlign: 'center', lineHeight: 1.3, fontSize: 13, color: '#a4a1a1'}}>
                            23 Sep 2023 @ 09:30 AM, GRANT HYATT KOCHI
                        </div>
                        
                    </div>
                    {/* <div style={{position: 'absolute', bottom: 50, left: 0, right: 0}}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div>
                                <IconButton onClick={e => navigate('/verify-mobile')} style={{
                                    background: colors.primary, 
                                    color: '#fff', 
                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                                }}  aria-label="next" size="large">
                                    <ArrowForwardIcon fontSize="inherit" />
                                </IconButton>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}