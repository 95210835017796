import Config from "../../Config";
import Menus from "../../Config/menus";
import colors from "../../Config/colors";
// import { StaticData } from "../../Data/StaticData";
import apiConstants from "../../Config/apiConstants";
import moment from "moment";
import { getAuthUser } from "../Services/Auth";
import { ImageConstants } from "../../Config/images";

export const getStaticData = (name) => {};


export const getAppConfig = (name) => Config[name];

export const setAppTitle = (name) => {

};

export const url = (url) => {
    let urlString = url.split('');
    if(urlString[0] !== '/') url = '/' + url
    return encodeURI(`${Config.API_URL}${url}`);
}

export const serverValidationError = (value) => value ? value[0] : null; 

export const getMenus = () => {

    let d = [];
    for (let i = 0; i < Menus.length; i++) {
        const element = Menus[i];
        if(element.access.includes(getAuthUser('role'))) {
            // d.push(element)
        }
        d.push(element)
    }

    return d
};

export const getColors = (clr) => {
    let d = colors[clr];
    if(! d) return clr;
    return d;
}

export const getImages = (k) => ImageConstants[k]

export const getApiConst = (clr, params = '') => {
    let d = apiConstants[clr];
    if(! d) return url(clr);
    return url(d) + params;
}

export const capitalize = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export const generateServerSideError = (errors) => {
    let keys = Object.keys(errors);
    let reSet = {};
    for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        reSet = {
            ...reSet,
            [element]: errors[element][0]
        }
    }

    return reSet;
}

export const setDropdownData = (data, keyPair = []) => {

    let resetValue = [];
    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        resetValue.push({
            value: element[keyPair[0]],
            label: element[keyPair[1]]
        })
    }
    return resetValue;
}

export const appDateFormat = (date = "") => {
    if(!date) return '---';
    return moment(date).format('ddd, DD, MMM YYYY hh:mm A');
} 

export const splitCamelCaseWithAbbreviations = (s, dLimiter = '-') => {

    try {
        return s.replace(/([^[\p{L}\d]+|(?<=[\p{Ll}\d])(?=\p{Lu})|(?<=\p{Lu})(?=\p{Lu}[\p{Ll}\d])|(?<=[\p{L}\d])(?=\p{Lu}[\p{Ll}\d]))/gu, dLimiter).toLowerCase()
    } catch (error) {
        
    }

    return s;
}

export const generateURLQuery = (query = {}) => {
   
    const keys = Object.keys(query);
    let string = "?";

    for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        string += `${element}=${query[element]}&`;
    }
    return string.replace(/&\s*$/, "");
}

export const likeSearch = (array, query = "") => {
    return array.filter((element) => {
        if (typeof element === 'string' && element.toLowerCase().includes(query.toLowerCase())) {
          return true;
        }
        return false; // No match found
    });
}


export const likeSearchObjectArray = (data, query) => {
    console.log(data)
    return data.filter((obj) => {
        for (const key of Object.keys(obj)) {
            if (typeof obj[key] === 'string' && obj[key].toLowerCase().includes(query.toLowerCase())) {
                return true;
            }
            if (typeof obj[key] === 'number' && obj[key] === query) {
                return true;
            }
        }
        return false;
    });
}

export const getResponsivePoint = () => '(min-width:900px)';

export const getAllChildren = (m, isChild = false) => {
    let ids = [];

    function traverseChildren(arr) {
        for (const item of arr) {
            ids.push(item);
            if (item.children && Array.isArray(item.children) && item.children.length > 0) {
                traverseChildren(item.children);
            }
        }
    }
    traverseChildren(m);
    return ids;
}

export const generateAutoPassword = (length) => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@!#$%^&*()_+_/{}[]'?><";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset.charAt(randomIndex);
    }
    return password;
}