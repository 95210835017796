import AppsIcon from '@mui/icons-material/Apps';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const menus = [
    {
        id: 1,
        name: "Dashboard",
        path: "/dashboard",
        access: ['all'],
        icon: AppsIcon
    },
    {
        id: 2,
        name: "Members",
        path: "/members",
        access: ['all'],
        icon: AppsIcon
    },
    {
        id: 3,
        name: "Masters",
        path: "#",
        access: ['all'],
        icon: ManageAccountsIcon,
        children: [
            {
                id: 4,
                name: "Category",
                path: "/categories",
                access: ['admin'],
                icon: CorporateFareIcon
            },
            {
                id: 5,
                name: "Services",
                path: "/services",
                access: ['admin'],
                icon: SupportAgentIcon
            }
        ]
    }
];

export default menus;