
export const TOKEN_NAME = "AUTH_TOKEN";
export const AUTH_USER = "AUTH_USER";
export const TOKEN_EXPIRES = "TOKEN_EXPIRES";
export const REQUEST_URL = "REQUEST_URL";
export const APP_TIME = "APP_TIME";

export const isAuth = () => localStorage.getItem(TOKEN_NAME) !== null;
export const getAuthToken = () => 'Bearer ' + localStorage.getItem(TOKEN_NAME);
export const getTokenExpires = () => localStorage.getItem(TOKEN_EXPIRES);
export const getAppTime = () => localStorage.getItem(APP_TIME);
export const getAuthUser = (key = "") => {
    let d = JSON.parse(localStorage.getItem(AUTH_USER))
    if(key && d) d = d[key]; 
    return d;
};

export const destroyAuth = () => {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(AUTH_USER);
    localStorage.removeItem(TOKEN_EXPIRES);
    localStorage.removeItem(REQUEST_URL);
    localStorage.removeItem(APP_TIME);
}

export const attemptAuth = (data) => {
    localStorage.setItem(TOKEN_NAME, data.accessToken);
    localStorage.setItem(TOKEN_EXPIRES, data.tokenExpires);
    localStorage.setItem(AUTH_USER, JSON.stringify(data.user));
}

export const setServerTime = (time) => {
    localStorage.setItem(APP_TIME, time);
}

export const checkRole = (key) => getAuthUser('role') === key ? true : false