import { Container } from "@mui/material";
import React from "react";
import colors from "../Config/colors";

export default function Layout({ children }) {

    return (
        <div style={{background: colors.primary, height: '40vh'}}>
            <div style={{padding: 15, height: 100}}>
               
            </div>
            <div style={{background: '#fff', borderTopLeftRadius: 20, borderTopRightRadius: 20, height: '46vh'}}>
                <Container maxWidth="xs" style={{paddingTop: 20}}>
                   {children}
                </Container>
            </div>
        </div>
    )
}