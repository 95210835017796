const apiConstants = {
    LOGIN: '/auth/login',

    CATEGORIES_DATA_TABLE: '/categories/all/data-table',
    CATEGORIES_ALL: '/categories',
    CATEGORIES_CREATE: '/categories',
    CATEGORIES_GET_BY_ID: '/categories',
    CATEGORIES_UPDATE: '/categories',
    CATEGORIES_REMOVE: '/categories',

    SERVICES_DATA_TABLE: '/services/all/data-table',
    SERVICES_CREATE: '/services',
    SERVICES_GET_BY_ID: '/services',
    SERVICES_UPDATE: '/services',
    SERVICES_REMOVE: '/services',

    MEMBERS_DATA_TABLE: '/members/all/data-table',
    MEMBERS_REMOVE: '/members',
    MEMBERS_BLOCK: '/members/status'
}


export default apiConstants;