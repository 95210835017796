import './App.css';
import Home from './Modules/Home';
import Home2 from './Modules/Home/index-reg';
import { Route, Routes } from 'react-router-dom';
import VerifyMobile from './Modules/VerifyMobile';
import OTPVerification from './Modules/VerifyMobile/OTPVerification';
import ShowMembers from './Modules/Members/Show';

function App() {
    return (
        <div className="App">
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/registration-v2z" element={<Home2 />} />
                <Route exact path="/verify-mobile" element={<VerifyMobile />} />
                <Route exact path="/otp-verification/:token" element={<OTPVerification />} />
                <Route exact path="/members/:id" element={<ShowMembers />} />
            </Routes>
        </div>
    );
}

export default App;
