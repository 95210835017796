import React from 'react';
import { getColors } from './../../Core/Utilities/index';

export default function Title({title, subTitle = "", fontSize = 16}) {

    return (
        <div style={{marginBottom: 5}}>
            <div style={{fontSize: fontSize, fontWeight: 'bold'}}>
                <div>{title}</div>
            </div>
            {subTitle ? <div style={{fontSize: 12, color: getColors('secondary')}}>{subTitle}</div> : null}
        </div>
    )
}

export function SubTitle({title, subTitle = "", fontSize = 14}) {

    return (
        <div style={{marginBottom: 5, marginTop: 10}}>
            <div style={{fontSize: fontSize, fontWeight: 'bold'}}>
                <div>{title}</div>
            </div>
            {subTitle ? <div style={{fontSize: 12, color: getColors('secondary')}}>{subTitle}</div> : null}
        </div>
    )
}