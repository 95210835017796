import React, { useState } from "react";
import colors from "../../Config/colors";
import { Container } from "@mui/material";
import TextBox from "../../Components/TextBox";
import Button from "../../Components/Button";
import VerifyMobileImage from "../../Assets/Mobile-login.jpg";
import { request } from "../../Core/Services/Request";
import { getApiConst } from "../../Core/Utilities";
import AppModalPopUp from "../../Components/AppModalPopUp";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";

export default function VerifyMobile() {

    const navigate = useNavigate();
    const [mobile, setMobile] = useState("");
    const [errors, setErrors] = useState({ mobile: "" });
    const [isSubmit, setIsSubmit] = useState(false);
    const [errorModal, setErrorModal] = useState({
        open: false,
        data: ""
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmit(true)
        request(getApiConst('/convention/verify/mobile'), { mobile }, 'POST')
            .then(res => {
                setIsSubmit(false);
                navigate('/otp-verification/' + res.result.uuid)
            })
            .catch(error => {
                setIsSubmit(false)
                setErrors(error.errors)
                if (error?.errors?.isKcclFolio === false) {
                    setErrorModal({
                        open: true, data: error
                    })
                }
            })
    }

    return (
        <Layout>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}><img src={VerifyMobileImage} alt="mobile" style={{ width: '55%' }} /></div>
            <div style={{ textAlign: 'left' }}>
                <div style={{ fontSize: 33, fontWeight: 'bold' }}>Welcome</div>
                <div style={{ marginTop: -5, color: '#a6a6a6' }}>Verify your mobile</div>
            </div>
            <form onSubmit={handleSubmit} style={{ marginTop: 20 }}>
                <TextBox value={mobile} error={errors.mobile} onChange={e => setMobile(e.target.value)} label="Registered Mobile" placeholder="Enter your registered mobile number" required autoFocus type="number" />
                <div style={{ marginTop: 10 }}>
                    <Button loading={isSubmit} type="submit" size="large" label="Register Now" fullWidth />
                </div>
            </form>
            <div style={{ height: 10 }}></div>
            <div style={{ background: '#fff7eb', fontSize: 12, lineHeight: 1.4, padding: 10, borderRadius: 5 }}>COA മെമ്പർഷിപ്പ് പോർട്ടലിൽ രജിസ്റ്റർ ചെയ്ത അതേ നമ്പർ ഉപയോഗിക്കുക</div>
            <div style={{ height: 10 }}></div>
            <AppModalPopUp onClose={e => setErrorModal({ open: false })} open={errorModal.open} title="Prompt">
                <div style={{ display: 'block', textAlign: 'center', color: 'red' }}>
                    <div>
                        <ReportGmailerrorredIcon style={{ fontSize: 50 }} />
                    </div>
                    <div style={{ fontWeight: 'bold', marginTop: 4, marginLeft: 3, textAlign: 'center' }}>{errorModal?.data?.message}</div>
                </div>
                <div style={{marginTop: 20, textAlign: 'center'}}>
                    <a href="https://reg.coakerala.com/" style={{color: 'blue'}} target="_blank">
                        Click to update
                    </a>
                </div>
            </AppModalPopUp>
        </Layout>
    )
}