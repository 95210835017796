import React, { useCallback, useEffect, useState } from "react";
import Layout from "../Layout";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { getApiConst } from "../../Core/Utilities";
import { request } from "../../Core/Services/Request";
import { useParams } from "react-router-dom";
import Button from "../../Components/Button";
import StatusIndicator from "../../Components/StatusIndicator";
import moment from "moment";

var refReshTimer;

export default function ShowMembers() {

    const { id } = useParams();

    const [data, setData] = useState("");
    const [image, setImage] = useState("");
    const [isUploading, setIsUploading] = useState(false);

    const getDetails = useCallback(() => {
        request(getApiConst('/convention/get-member-details/') + id)
        .then(res => {
            setData(res.result)
            if(res.result.userImage) setImage(res.result.userImage)
            if(res?.result?.card?.cardGeneratingStatus === 'generated') {
                clearInterval(refReshTimer)
            }
        })
        .catch(error => {
        })
    }, [id])

    useEffect(() => {
        getDetails()
    }, [getDetails])

    const handleFileChange = (event) => {
        setIsUploading(true)
        const formData = new FormData();
        formData.append('image', event.target.files[0])
        request(getApiConst('/convention/upload-image/') + data.id, formData, 'POST', true)
        .then(res => {
            getDetails()
            setIsUploading(false)
        })
        .catch(error => {
        })
    }

    const handleConfirm = () => {
        request(getApiConst('/convention/confirm-registration/') + data.id, {}, 'POST')
        .then(res => {
            getDetails();
            refReshTimer = setInterval(() => {
                getDetails();
            }, 5000)
        })
        .catch(error => {
        })
    }

    return (
        <Layout>
            <div style={{textAlign: 'center'}}>
                <div style={{justifyContent: 'center', display: 'flex', marginTop: 10}}>
                    <label style={{background: '#e6e6e6', display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center', height: 265, width: 200, borderRadius: 4, border: '1px dashed #c1b2b2', objectFit: 'contain', padding: 10, position: 'relative'}}>
                        {image ? 
                            <div>
                                <img src={image} style={{width: '100%', height: '100%', objectFit: 'contain'}} alt="KCCL-member"/>
                                    <div style={{fontSize: 12, position: 'absolute', bottom: 5, left: 0, right: 0}}>
                                        {isUploading ? 'Uploading...' : 'Click to change' }
                                    </div>
                                </div>
                        :
                        <div style={{textAlign: 'center'}}>
                            <div><UploadFileIcon style={{fontSize: 35}}/></div>
                            <div style={{color: '#989292', marginTop: 10}}>Upload your image</div>
                        </div>}
                        <input onChange={handleFileChange} type="file" accept=".jpeg, .png, .jpg" style={{display: 'none'}} />
                    </label>
                    
                </div>
                <div style={{marginTop: 10}}>
                    <div style={{fontSize: 20, fontWeight: 'bold'}}>{data.register_no}</div>
                    <div style={{fontSize: 16, fontWeight: 'bold'}}>{data.name}</div>
                    <div style={{fontSize: 12, fontWeight: 'bold'}}>{data.email}</div>
                    <div style={{fontSize: 12, fontWeight: 'bold'}}>{data.mobile}</div>
                </div>
            </div>
            {data?.card?.id && <div style={{background: '#e5eed9', position: 'relative', padding: 10, marginTop: 10, borderRadius: 3, border: '1px dashed #bec0bb'}}>
                <div>Registration: <b>{data?.card?.registerNo}</b></div>
                <div style={{fontSize: 12, marginBottom: 10}}>Generated On {moment(data?.card?.created_at).format('DD, MMM YYYY hh:mm:ss A')}</div>
                <div style={{fontSize: 12, marginBottom: 10, position: 'absolute', right: 10, top: 10}}><StatusIndicator status={data?.card?.cardGeneratingStatus == 'pending' ? 'Generating ID' : data?.card?.cardGeneratingStatus}/></div>
                {data?.card?.cardGeneratingStatus === 'generated' && 
                    <a href={data?.card?.cardPath} style={{color: 'blue'}} download><>Click here to download ID Card</></a>
                }
            </div>}
            {/* {!data?.card?.id &&  */}
                <div style={{position: 'absolute', bottom: 10, right: 10, left: 10}}>
                    <Button disabled={!Boolean(image) || isUploading} onClick={handleConfirm} label="Confirm your details" fullWidth size="large"/>
                </div>
            {/* } */}
        </Layout>
    )
}