import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import ReactInputVerificationCode from "react-input-verification-code";
import Button from "../../Components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { request } from "../../Core/Services/Request";
import apiConstants from "../../Config/apiConstants";
import { getApiConst } from "../../Core/Utilities";

export default function OTPVerification() {

    const navigate = useNavigate();
    const { token } = useParams();
    const [otp, setOtp] = useState('');
    const [timerCount, setTimerCount] = useState("01:00");
    const [enableReSent, setEnableReSent] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [optValue, setOtpValue] = useState(null);
    const [error, setError] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [validOTP, setValidOTP] = useState(true);

    useEffect(() => {
        countDown();
        setIsLoading(true)
        request(getApiConst('/otp-management/check-token/') + token)
        .then(res => {
            setIsLoading(false)
        })
        .catch(error => {
            setValidOTP(false)
        })
    }, [navigate, token])

    const countDown = () => {
        var countdownTime = 120;
        var countdownInterval = setInterval(function() {
            countdownTime--;
            var minutes = Math.floor(countdownTime / 60);
            var seconds = countdownTime % 60;
            if (minutes < 10) minutes = "0" + minutes;
            if (seconds < 10) seconds = "0" + seconds;
            var timeString = minutes + ":" + seconds;
            setTimerCount(timeString)
            if (countdownTime === 0) {
                clearInterval(countdownInterval);
                setEnableReSent(true);
            }
        }, 1000);
    }

    const handleResentOTP = () => {
        if(enableReSent)
        request(getApiConst('/otp-management/send?module=convention-registration'), {
            token
        }, 'POST')
        .then(res => {
            setEnableReSent(false)
            countDown();
        })
    }

    const handleSubmit = () => {
        setEnableSubmit(false);
        setIsSubmit(true)
        request(getApiConst('/otp-management/verify?module=convention-registration'), {
            token,
            otp: optValue
        }, 'POST')
        .then(res => {
            setIsSubmit(false)
            navigate('/members/' + res.result.uuid + '?module=convention-registration')
        })
        .catch(error => {
            setError(error.message);
            setIsSubmit(false)
        })
    }

    if(!validOTP) return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', fontSize: 20}}>Invalid request</div>

    return (
        <Layout>
            <div style={{ textAlign: 'left' }}>
                <div style={{ fontSize: 25, fontWeight: 'bold' }}>Verify OTP</div>
            </div>
            <div className='otp-field-container' style={{display: 'flex', marginTop: 30, justifyContent: 'center', marginBottom: 5}}>
                <ReactInputVerificationCode onChange={e => {
                    if(parseInt(e).toString().length === 5) {
                        setOtpValue(parseInt(e));
                        return setEnableSubmit(true);
                    }
                    setEnableSubmit(false)
                }} length={5} autoFocus/>
            </div>
            <Errors label={error} />
            <div style={{marginTop: 20}}>
                <div style={{textAlign: 'center', padding: 10}}><b>{timerCount}</b></div>
                <div style={{color: enableReSent ? 'unset' : 'grey'}}>OTP Not Received? <span onClick={handleResentOTP} style={{color: enableReSent ? 'blue' : 'grey', cursor: 'pointer'}}> <u>Send again</u></span></div>
            </div>
            <Button style={{marginTop: 10}} label={isSubmit ? 'Please wait...' : 'CONFIRM OTP' } disabled={!enableSubmit} onClick={handleSubmit} size='medium' fullWidth variant='contained' />
        </Layout>
    )
}

export function Errors({label = ""}) {

    if(! label) return "";
    return <div style={{color: 'red'}}>{label}</div>
}